<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="register_wrapper">
            <div class="register_header_wrapper">
              <div class="register_header">
                <img src="@/assets/img/bici/logo.png" width="40"/>
                <div class="register_title">
                  <small>CRISP | CP</small>
                  <span>{{ title }}</span>
                </div>
              </div>
              <div class="back_button">
                <router-link to="/SignIn"><i class="mdi mdi-arrow-left"></i></router-link>
              </div>
            </div>
            <div class="forgot_text">
              <p>
                Please, enter your email address.<br>You will receive random password.<br>请输入您的电子邮件地址。 您将收到随机密码。
              </p>
            </div>
            <v-form  v-model="valid" ref="form">
              <div class="forgot_box_wrapper">          
                <div class="forgot_box">
                  <div class="input_fields">
                    <v-text-field 
                      v-model="Email"
                      required
                      name="email" 
                      label="Email" 
                      type="text"
                      :rules="emailRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="button_wrapper">
                  <v-btn color="primary" @click="correct">ENTER</v-btn>
                </div>
              </div>
            </v-form>

            <div class="message" :class="[messagered ? 'messagered' : 'messagegreen']">
              <div>{{messageEN}}</div>
              <div>{{messageCH}}</div>
            </div>
          </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_forgot.styl'
import { ApplicationService }  from "@/services"

export default {
  name: 'Forgot',
  mixins: [
    
  ],
  data: () => ({
    messagered:'',
    Email: '',
    messageEN: '',
    messageCH: '',
    title: 'Forgot Password',
    selectedItem: {},
    requestApplied: false,
    accountService: null,
    applicationService: null,
    valid: false,
    emailRules: [
      v => !!v || 'Required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail is not valid'
    ],

  }),
  watch:{
    Email(v){
     if (v.length == 0) this.messageEN = '' 
     if (v.length == 0) this.messageCH = '' 
    }
  },
  created() {
    this.applicationService = new ApplicationService()
  },
  methods: {
  
    correct() {
      this.$refs.form.validate()

      if(!this.valid) return
      this.selectedItem.email = this.Email
      this.selectedItem.id = this.selectedItem.email

        this.applicationService.uniqMember(this.selectedItem.id).then(res => {
        
        if (res == 1) {

          this.applicationService.forgotMember(this.selectedItem.id, res => {
          })
          this.valid = true
          this.messageEN = 'Temporary password was sent by email.'
          this.messageCH = '临时密码是通过电子邮件发送的。'
          this.messagered = false

        }else if (res < 1){
          this.valid = true
          this.messageEN = 'This is not a registered email address. Please try again.'
          this.messageCH = '这不是注册的电子邮件地址。 请再试一遍'
          this.messagered = true
        }
      })


    },

  }
}
</script>